.ql-toolbar.ql-snow,
.ql-snow.ql-tooltip,
.ql-container.ql-snow{
  border: none;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: var(--2px) solid var(--color-border);
}

.ql-toolbar {
  max-width: 90%;
}

.ql-editor {
  background-color: var(--color-white);
}
