@media print {
  // This fixes certain elements being cut off on Chrome
  * {
    overflow: visible !important;
  }

  // Hide material design components
  .cdk-overlay-container {
    display: none;
  }

  .header {
    display: none;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .page-break {
    page-break-after: always;
  }
}
