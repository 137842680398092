.removed {
  color: var(--color-status-removed);
}

.pending {
  color: var(--color-status-pending);
}

.ongoing {
  color: var(--color-status-ongoing);
}

.finished {
  color: var(--color-status-finished);
}

.expired {
  color: var(--color-status-expired);
}

.removed-conversation-record {
  opacity: 0.5;
}