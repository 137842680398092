/*
  Generic gap classes
  .gap-[horizontal/vertical]-[size]
*/

.gap-h-4 > *:not(:first-child) {
  margin-left: var(--4px);
}

.gap-v-4 > *:not(:first-child) {
  margin-top: var(--4px);
}

.gap-h-8 > *:not(:first-child) {
  margin-left: var(--8px);
}

.gap-v-8 > *:not(:first-child) {
  margin-top: var(--8px);
}

.gap-h-16 > *:not(:first-child) {
  margin-left: var(--16px);
}

.gap-v-16 > *:not(:first-child) {
  margin-top: var(--16px);
}

.gap-h-24 > *:not(:first-child) {
  margin-left: var(--24px);
}

.gap-v-24 > *:not(:first-child) {
  margin-top: var(--24px);
}

.gap-h-32 > *:not(:first-child) {
  margin-left: var(--32px);
}

.gap-v-32 > *:not(:first-child) {
  margin-top: var(--32px);
}

.gap-h-48 > *:not(:first-child) {
  margin-left: var(--48px);
}

.gap-v-32 > *:not(:first-child) {
  margin-top: var(--48px);
}

.gap-h-64 > *:not(:first-child) {
  margin-left: var(--64px);
}

.gap-v-64 > *:not(:first-child) {
  margin-top: var(--64px);
}

@media screen and (max-width: 768px) {
  .gap-h-4.flex-col-l-c-when-small > *:not(:first-child),
  .gap-h-8.flex-col-l-c-when-small > *:not(:first-child),
  .gap-h-16.flex-col-l-c-when-small > *:not(:first-child),
  .gap-h-24.flex-col-l-c-when-small > *:not(:first-child),
  .gap-h-32.flex-col-l-c-when-small > *:not(:first-child),  
  .gap-h-48.flex-col-l-c-when-small > *:not(:first-child),
  .gap-h-64.flex-col-l-c-when-small > *:not(:first-child) {
    margin-left: 0;
  }
}
