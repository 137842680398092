.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
  pointer-events: none;
}

.not-selectable {
  user-select: none;
}

.draggable {
  cursor: move;
}
