:root {
  --mat-stepper-header-height: 60px;
}

.mat-step-icon {
  background-color: var(--color-primary) !important;
}

.mat-step-label {
  font-family: 'DM Sans Regular', sans-serif !important;
}

vh-timeline {
  // Make the timeline a bit more condensed
  .mat-vertical-stepper-header {
    align-items: flex-start;
    height: unset;
  }

  .mat-step-header {
    cursor: unset;
  }

  // Prevent the selected item from being bold and larger
  .mat-step-label,
  .mat-step-label.mat-step-label-selected {
    font-size: var(--14px);
    font-weight: normal;
  }

  // Hide the icons or index numbers on the stepper
  .mat-step-icon-content {
    display: none;
  }

  // Disable the hover effect on a timeline item
  .mat-step-header:hover:not([aria-disabled]) {
    background-color: unset;
  }

  // Set the width and height of a timeline dot
  .mat-step-icon {
    height: 16px;
    width: 16px;
  }

  // Adjust the vertical line between timeline dots according to the dimensions of the dot
  .mat-vertical-content-container {
    margin-left: 31px;
  }

  // Remove some extra padding below the timeline
  .mat-vertical-content {
    padding: unset;
  }
}

