html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p {
  display: block;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body,
input {
  @extend .font-regular-16-19;
  color: var(--color-black);
}
input:disabled {
  color: var(--color-secondary-grey);
}

h1 {
  @extend .font-medium-24-29;
  font-weight: normal;
}

h2 {
  @extend .font-medium-18-22;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: var(--color-black);
  cursor: pointer;
}

hr {
  margin: 0 var(--32px);
  border: var(--1px) solid var(--color-border);
}

b {
  font-family: 'DM Sans Bold', sans-serif;
}
