.mat-mdc-dialog-surface {
  padding: var(--16px) !important;
  border-radius: var(--8px) !important;
  box-shadow: 1px 1px 6px #00000029;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--color-black) !important;
  font-family: 'DM Sans Regular', sans-serif !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
}

.dialog-size-tiny {
  max-width: var(--400px) !important;
}

.dialog-size-medium {
  max-width: var(--600px) !important;
}

.dialog-size-large {
  max-width: var(--1280px) !important;
}

.dialog-grey {
  .mat-mdc-dialog-surface {
    background-color: var(--color-background-grey) !important;
  }
}