.content-block-padding {
  padding: var(--16px) var(--16px);
}

.padding-xl {
  padding: var(--32px) var(--32px);
}

.padding-m {
  padding: var(--8px) var(--8px);
}
