/*
  Generic flex classes
  .flex-[1]-[2]-[3]

  1. direction: column/row
  2. horizontal alignment: left/right/center/space-between/null
  3. vertical alignment: top/bottom/center/space-between/null
*/
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-l-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-col-r-b {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-col-l-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flex-col-r-c {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.flex-col-r-r {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
}

.flex-col-r-s {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.flex-col-c-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-col-c-t {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.flex-col-c-s {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex-col-l-s {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-l-c {
  display: flex;
  justify-content: left;
  align-items: center;
}

.flex-row-a-c {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-row-st-c {
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.flex-row-l-t {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.flex-row-c-n {
  display: flex;
  justify-content: center;
}

.flex-row-s-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-s-t {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-row-se-c {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row-r-t {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.flex-row-r-c {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-row-c-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-row-c-s {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-r-s {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-start {
  margin-right: auto;
  align-self: start;
  text-align: start;
}

.align-end {
  margin-left: auto;
  align-self: end;
  text-align: end;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  text-align: center;
}

@media (max-width: 768px) {
  .flex-col-l-c-when-small {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8px);

    .align-end {
      margin-right: auto;
      align-self: start;
      text-align: start;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
  gap: var(--16px);
}