input[type='radio'] {
  appearance: none;
  margin: 0;
  color: var(--color-primary);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--color-primary);
  border-radius: 50%;
  display: grid;
  place-content: center;

  &[disabled] {
    opacity: 0.6;
  }

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-primary);
  }

  &.checkbox-s {
    width: 1em;
    height: 1em;

    &::before {
      width: 0.5em;
      height: 0.5em;
    }
  }
}

input[type='radio']:checked::before {
  transform: scale(1);
}
