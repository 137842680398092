@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.anim-rotating {
  animation: rotating 1s linear infinite;
  transform-origin: center center;
  -webkit-transform-origin: center center;
}

@keyframes pop-in {
  from {
    transform: scale(0%);
  }
  to {
    transform: scale(100%);
  }
}

.anim-pop-in {
  animation: pop-in 0.1s ease-in-out;
}

.anim-pop-out {
  animation: pop-in 0.1s ease-in-out reverse;
}

@keyframes pulsating-opacity {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.anim-pulsating {
  animation: pulsating-opacity 1s ease-in-out alternate infinite;
}

@keyframes subtle-enlarge {
  from {
    transform: scale(100%);
  }
  to {
    transform: scale(105%);
  }
}

.anim-subtle-enlarge-in {
  animation: subtle-enlarge 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

.anim-subtle-enlarge-out {
  animation: subtle-enlarge 0.4s ease-in-out reverse;
  animation-fill-mode: forwards;
}

.anim-ripple {
  animation: ripple 1.5s ease-in-out infinite;
  border-radius: var(--border-radius-normal);
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 var(--color-primary-8-tranparent), 0 0 0 0 var(--color-primary-8-tranparent);
  }

  50% {
    box-shadow: 0 0 0 20px var(--color-tertiary-8-tranparent), 0 0 0 40px var(--color-tertiary-8-tranparent);
  }

  100% {
    box-shadow: 0 0 0 30px var(--color-border-50), 0 0 0 60px var(--color-border-50);
  }
}

.anim-heart-beat {
  animation: heart-beat 1s infinite ease-in-out alternate;
  transform-origin: center;
}

@keyframes heart-beat {
  to {
    transform: scale(1.3);
  }
}
