.position-absolute-0 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-loader-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;

  .loader {
    width: var(--64px);
    position: relative;
    margin-top: var(--6px);
    scale: 0.8;
  }
}