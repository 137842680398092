:root {
  ---4px: -0.25rem;
  --1px: 0.063rem;
  --2px: 0.125rem;
  --3px: 0.188rem;
  --4px: 0.25rem;
  --6px: 0.375rem;
  --8px: 0.5rem;
  --11px: 0.688rem;
  --12px: 0.75rem;
  --14px: 0.875rem;
  --16px: 1rem;
  --17px: 1.063rem;
  --18px: 1.125rem;
  --19px: 1.188rem;
  --20px: 1.25rem;
  --22px: 1.375rem;
  --23px: 1.438rem;
  --24px: 1.5rem;
  --28px: 1.75rem;
  --29px: 1.813rem;
  --30px: 1.875rem;
  --32px: 2rem;
  --34px: 2.125rem;
  --40px: 2.5rem;
  --48px: 3rem;
  --56px: 3.5rem;
  --64px: 4rem;
  --80px: 5rem;
  --96px: 6rem;
  --128px: 8rem;
  --192px: 12rem;
  --200px: 12.5rem;
  --250px: 15.75rem;
  --300px: 18.75rem;
  --400px: 25rem;
  --600px: 37.5rem;
  --960px: 60rem;
  --1280px: 80rem;
}

.full-width {
  width: 100%;
}

.date-width {
  min-width: var(--80px);
}
