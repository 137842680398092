.toast {
  --mdc-snackbar-supporting-text-font: 'DM Sans Regular', sans-serif;

  @extend .font-regular-16-19;
  --mdc-snackbar-container-color: var(--color-white);
  --mat-mdc-snack-bar-button-color: var(--color-primary);
  --mat-snack-bar-button-color: var(--color-primary);
  --mdc-snackbar-supporting-text-color: var(--color-black);

  &.error {
    --mat-mdc-snack-bar-button-color: var(--color-error);
    --mat-snack-bar-button-color: var(--color-error);
    --mdc-snackbar-supporting-text-color: var(--color-error);
  }
}
