/**** This file contains overrides for the default settings of the Angular Calendar library ****/
/* Change the default color of the current time marker */
.cal-week-view .cal-current-time-marker {
  background-color: var(--color-primary);
}

.cal-week-view .cal-header.cal-today,
.cal-month-view .cal-day-cell.cal-today {
  background-color: var(--color-primary-8-tranparent);
}

/* Create a badge for the cal-week-view because it does not exist by default */
.cal-week-view .cal-day-badge {
  width: fit-content;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  border-radius: var(--border-radius-round);
}

/* Change the default color of the day event counter badges */
.cal-month-view .cal-day-badge,
.cal-week-view .cal-day-badge {
  background-color: var(--color-primary);
  color: var(--color-white);
}

/* Make the header of the calendar day/week view sticky */
.cal-week-view {
  overflow-y: auto;
  height: 100%;
}

.cal-day-headers {
  position: sticky;
  top: 0;
  background: var(--color-white);
  z-index: 3;

  .cal-header:nth-of-type(1) {
    border-left: solid 1px #e1e1e1;
  }
}

/* Change the default color of the calendar event tooltip */
.cal-tooltip-inner {
  background-color: var(--color-background-tooltip);
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  border-top-color: var(--color-background-tooltip);
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  border-right-color: var(--color-background-tooltip);
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  border-bottom-color: var(--color-background-tooltip);
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  border-left-color: var(--color-background-tooltip);
}

.cal-time {
  padding: var(--4px) var(--8px) var(--4px) 0;
}

.cal-cell {
  position: relative;
}


.cal-week-view,
.cal-month-view {
  border-radius: var(--border-radius-minimal);
}

.cal-days {
  border-bottom-left-radius: var(--border-radius-minimal);
  border-bottom-right-radius: var(--border-radius-minimal);
}
