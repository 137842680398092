.is-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Hide the branding of TinyMCE in the status bar */
.tox-statusbar__branding {
  display: none;
}

:root {
  --navigation-element-width: 184px;
  --navigation-transition:
        transform 0.5s ease-in-out,
        margin 0.5s ease-in-out,
        gap 0.5s ease-in-out,
        max-width 0.5s ease-in-out,
        width 0.5s ease-in-out,
        opacity 0.5s ease-in-out;
}
