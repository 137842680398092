.list {
  list-style: none;

  li {
    @extend .flex-row-l-c;

    &:before {
      content: '\2022';
      margin: 0 var(--8px);
      color: var(--color-secondary-grey);
    }
  }
}
