:root {
  --table-border: solid 2px var(--color-background-grey);
}

table {
  border-collapse: collapse;
  border-radius: var(--border-radius-minimal);
  overflow: hidden;
}

thead {
  color: var(--color-secondary-grey);
  background-color: var(--color-background-grey);
}

th,
td {
  padding: var(--16px) var(--8px);
  text-align: left;
}

tr {
  @extend .table-row;
}

.table-row {
  border-bottom: var(--table-border);
}

.table-cell {
  border-right: var(--table-border);
}

.table-column-s {
  max-width: 100px;
  word-wrap: break-word;
}
