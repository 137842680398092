.skeleton {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: var(--4px);
  background-color: var(--color-border);

  & > * {
    display: none;
  }
}

.skeleton-animation {
  @media (prefers-reduced-motion: no-preference) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: var(--128px);
      background: linear-gradient(90deg, var(--color-border-50) 0%, var(--color-white) 50%, var(--color-border-50) 100%);
      background-size: var(--128px) 100%;
      animation: loading 2s ease-in-out infinite;
    }

    @keyframes loading {
      0% {
        left: calc(0px - var(--128px));
      }

      100% {
        left: 100%;
      }
    }
  }
}
