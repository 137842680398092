:root {
  // White
  --color-white: #ffffff;

  // Black
  --color-black: #2A3A51; //Text

  // Colors
  --color-primary: #20BBC0; // Primary green
  --color-primary-8-tranparent: #20bbc018; // Primary green, 8% transparant

  --color-secondary-green: #0B759F;

  --color-accent: #FF3366; // Accent color

  --color-tertiary: #6DE6CF; //Tertiary
  --color-tertiary-8-tranparent: #6de6d018; //Tertiary, 8% transparant

  // Alert colors
  --color-alert: #f46161;
  --color-success: #a1e887;
  --color-warning: #ffa739;
  --color-error: #de3c4b;

  --color-background-warning: #ff9c3921;
  --color-background-error: #f048593f;

  // Status colors
  --color-status-removed: #de3c4b;
  --color-status-pending: #fe7f2d;
  --color-status-ongoing: #ffaf2d;
  --color-status-finished: #80c665;
  --color-status-expired: #f6848c;

  // Greys
  --color-border: #D7DADE; // Border/seperator
  --color-border-50: #d7dade77; // --color-border, 50%

  --color-background-tooltip: #616161;
  --color-background-grey: #F9FAFB; //Background content
  --color-secondary-grey: #a8a8a8; // Slightly darker gray
  --color-input-placeholder: #949CB1 ; // --color-black, 50%

  // Override for phone component
  --mat-option-selected-state-label-text-color: var(--color-primary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-secondary-grey);
  --mat-option-hover-state-layer-color: var(--color-secondary-grey);
  --mat-option-focus-state-layer-color: var(--color-primary-8-tranparent);
  --mat-option-selected-state-layer-color: var(--color-primary-8-tranparent);
}

.has-background-white {
  background-color: var(--color-white);
}

.has-background-primary {
  background-color: var(--color-primary);
}

.has-background-grey {
  background-color: var(--color-background-grey);
}

.color-preview {
  width: 16px;
  height: 16px;
  border-radius: var(--border-radius-minimal);
}

.color-preview-round {
  width: 16px;
  height: 16px;
  border-radius: var(--border-radius-round);
}

.hatched {
  background: repeating-linear-gradient(
    -45deg,
    #fcfcfcaf,
    #fcfcfcaf 10px,
    #2f2f2f80 10px,
    #2f2f2f80 20px
  );
  border-radius: var(--border-radius-normal);
}
