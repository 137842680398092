.mollie-card-component {

  label {
    display: block;
    margin-bottom: var(--8px);
    font-size: var(--18px);
    line-height: var(--22px);
  }

  .mollie-component {
    padding: var(--8px);
    border-radius: var(--border-radius-minimal);
    background-color: var(--color-white);
    border: solid var(--1px) var(--color-border);

    &.is-invalid {
      border-color: var(--color-alert);
    }
  }

  .mollie-card-component__error {
    color: var(--color-alert);
    margin-top: var(--8px);
    font-family: "DM Sans Light", sans-serif;
    font-size: var(--12px);
    line-height: var(--14px);
  }
}


