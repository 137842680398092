.p-splitbutton {
    gap: var(--4px);
    font-family: "DM Sans Medium", sans-serif;
    margin-top: var(--3px);

    .p-button {
        background: var(--color-background-grey);
        border: none;
        height: 34px;
        padding: 0 12px;
        font-family: inherit;

        .p-button-label {
            padding: 4px;
        }

        &:enabled:hover {
            cursor: pointer;
        }

        &:first-child {
            border-radius: var(--4px) 0 0 var(--4px);
        }
    }

    .p-splitbutton-menubutton {
        border-radius: 0 var(--4px) var(--4px) 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        
        .p-icon-wrapper {
            display: flex;
            align-items: center;
        }
    }
    
    .p-tieredmenu-overlay {
        margin-top: var(--4px);
        padding: var(--2px);
    
        border-radius: var(--border-radius-minimal);
        box-shadow: 3px 3px 6px #00000029;
        background-color: var(--color-background-grey);
    
        display: flex;
        align-items: flex-end;
    
        min-width: 100%;

        p-tieredmenusub {
            width: 100%;
    
            .p-menuitem {
                margin-bottom: var(--2px);
                padding: var(--2px);
                border-radius: var(--border-radius-minimal);

                :not(:last-child):after {
                    border-bottom: var(--1px) solid var(--color-white);
                }
        
                :hover {
                    background-color: var(--color-border-50);
                    border-radius: var(--border-radius-minimal);
                }

                .p-menuitem-text {
                    padding: var(--8px);
                    font-family: inherit;
                    font-size: var(--14px);
                }
            }
        }
    }
}