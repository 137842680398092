@import '../node_modules/angular-calendar/css/angular-calendar';
@import '../node_modules/quill/dist/quill.snow.css';
@import 'styles/angular-calendar';
@import 'styles/animations';
@import 'styles/border';
@import 'styles/card';
@import 'styles/colors';
@import 'styles/cursor';
@import 'styles/elements';
@import 'styles/flex';
@import 'styles/fonts';
@import 'styles/gap';
@import 'styles/layout';
@import 'styles/list';
@import 'styles/mat-dialog';
@import 'styles/mat-stepper';
@import 'styles/miscellaneous';
@import 'styles/mollie';
@import 'styles/popover';
@import 'styles/ngx-quill';
@import 'styles/padding';
@import 'styles/positioning';
@import 'styles/print';
@import 'styles/profile-avatar';
@import 'styles/radio-buttons';
@import 'styles/size';
@import 'styles/skeleton';
@import 'styles/split-button';
@import 'styles/status';
@import 'styles/table';
@import 'styles/text';
@import 'styles/toast';
@import 'styles/phone-input';
