:root {
  --border-radius-minimal: 4px;
  --border-radius-normal: 16px;
  --border-radius-round: 999px;

  --border-normal: 1px;
  --border-fat: 2px;
}

.border {
  border: var(--1px) solid var(--color-border);
  border-radius: var(--border-radius-normal);
}

.border-minimal {
  border: var(--1px) solid var(--color-border);
  border-radius: var(--border-radius-minimal);
}

.border-medium {
  border: var(--2px) solid var(--color-border);
  border-radius: var(--border-radius-normal);
}

.underline-fat {
  border-bottom: var(--border-fat) solid var(--color-primary);
}

.border-error {
  border: var(--2px) solid var(--color-error);
  border-radius: var(--border-radius-minimal);
}

.border-dashed {
  border: var(--2px) dashed var(--color-background-grey);
  border-radius: var(--border-radius-minimal);
}

.border-primary {
  border: var(--2px) solid var(--color-primary);
  border-radius: var(--border-radius-minimal);
}
