.has-text-white {
  color: var(--color-white);
}

.has-text-primary {
  color: var(--color-primary);
}

.has-text-secondary {
  color: var(--color-secondary-green);
}

.has-text-tertiary-green {
  color: var(--color-tertiary);
}

.has-text-black {
  color: var(--color-black);
}

.has-text-tertiary {
  color: var(--color-secondary-grey);
}

.has-text-warning {
  color: var(--color-warning);
}

.has-text-alert {
  color: var(--color-alert);
}

.has-text-success {
  color: var(--color-success);
}

.has-text-error {
  color: var(--color-error);

  // Improves highlighting links in error text
  & a {
    text-decoration: underline;
  }
}

.has-text-background-grey {
  color: var(--color-background-grey);
}
