@font-face {
  font-family: 'DM Sans Light';
  src: url('../assets/fonts/DMSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'DM Sans Regular';
  src: url('../assets/fonts/DMSans-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'DM Sans Medium';
  src: url('../assets/fonts/DMSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'DM Sans Bold';
  src: url('../assets/fonts/DMSans-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'DM Sans Italic';
  src: url('../assets/fonts/DMSans-Italic.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans Bold Italic';
  src: url('../assets/fonts/DMSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

/*
  mixins
*/

@mixin safari-blurry-font-fix {
  -webkit-transform: translate3d(0, 0, 1px);
  -moz-transform: translate3d(0, 0, 1px);
  -ms-transform: translate3d(0, 0, 1px);
  -o-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

/*
  Generic font classes
  .font-[font-family]-[font-size]-[line-height]
*/
.font-light-12-14 {
  font-family: 'DM Sans Light', sans-serif;
  font-size: var(--12px);
  line-height: var(--14px);
}

.font-light-12-16 {
  font-family: 'DM Sans Light', sans-serif;
  font-size: var(--12px);
  line-height: var(--16px);
}

.font-light-14-17 {
  font-family: 'DM Sans Light', sans-serif;
  font-size: var(--14px);
  line-height: var(--17px);
}

.font-light-16-19 {
  font-family: 'DM Sans Light', sans-serif;
  font-size: var(--16px);
  line-height: var(--19px);
}

.font-regular-12-14 {
  font-family: 'DM Sans Regular', sans-serif;
  font-size: var(--12px);
  line-height: var(--14px);
}

.font-regular-14-17 {
  font-family: 'DM Sans Regular', sans-serif;
  font-size: var(--14px);
  line-height: var(--17px);
}

.font-regular-16-19 {
  font-family: 'DM Sans Regular', sans-serif;
  font-size: var(--16px);
  line-height: var(--19px);
}

.font-regular-18-22 {
  font-family: 'DM Sans Regular', sans-serif;
  font-size: var(--18px);
  line-height: var(--22px);
}

.font-medium-16-19 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--16px);
  line-height: var(--19px);
  @include safari-blurry-font-fix;
}

.font-medium-18-22 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--18px);
  line-height: var(--22px);
  @include safari-blurry-font-fix;
}

.font-medium-19-23 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--19px);
  line-height: var(--23px);
  @include safari-blurry-font-fix;
}

.font-medium-22-28 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--22px);
  line-height: var(--28px);
  @include safari-blurry-font-fix;
}

.font-medium-24-29 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--24px);
  line-height: var(--29px);
  @include safari-blurry-font-fix;
}

.font-medium-28-34 {
  font-family: 'DM Sans Medium', sans-serif;
  font-size: var(--28px);
  line-height: var(--34px);
  @include safari-blurry-font-fix;
}

/**
 Other text manipulation classes
 */

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
