.phone-input-container {
  margin-top: var(--4px);
  width: 100%;

  label {
    &.required {
      &::after {
        content: ' *';
        color: var(--color-alert);
      }
    }
  }

  // Label
  .main-label {
    font-family: 'DM Sans Regular', sans-serif !important;
    font-size: var(--18px) !important;
    line-height: var(--22px) !important;
    display: inline-block !important;
    margin-bottom: var(--4px) !important;
  }

  // Input
  .tel-form {
    border: var(--1px) solid var(--color-border) !important;
    border-radius: var(--border-radius-minimal) !important;
    background-color: var(--color-white) !important;
  }

  &.error {
    .tel-form {
      border-color: var(--color-error) !important;
      outline: unset !important;
    }
  }

  // Icon
  .mat-mdc-form-field-icon-suffix {
    display: none !important;
  }

  // Floating label for number
  .mdc-floating-label--float-above {
    color: var(--color-primary) !important;
  }

  // Error label for number
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    .mdc-floating-label,
    .mdc-floating-label--float-above {
      color: var(--color-error) !important;
    }
  }
}
